const formFields = {
    "Nr": "",
    "Rechercheupdate Stand": "",
    "Name der Technologie": "",
    "Kurzbeschreibung": "",
    "Technologiebeschreibung": "",
    "Unterstützungsart": "",
    "Unterstützungsart 2": "",
    "Unterstützungsart 3": "",
    "Berücksichtigte Behinderungsarten (übergeordnet)": "",
    "Berücksichtigte Behinderungsarten (übergeordnet) 2": "",
    "Berücksichtigte Behinderungsarten (übergeordnet) 3": "",
    "Berücksichtigte Behinderungsarten (übergeordnet) 4": "",
    "Berücksichtigte Behinderungsarten (übergeordnet) 5": "",
    "Berücksichtigte Behinderungsarten (spezifische Behinderung)": "",
    "ICF-Kategorien": "",
    "Schwerpunkt der unterstützten Tätigkeit (Übergreifende Typisierung)": "",
    "Reifegrad": "",
    "Laufzeit von Projekten": "",
    "Verfügbarkeit von Produkten": "",
    "Grundvoraussetzungen zur Anwendung": "",
    "Barrierefreiheit": "",
    "Verfügbare Sprache(n) der Technologie": "",
    "Technische Anforderungen": "",
    "Interoperabilität / Anschlussfähigkeit": "",
    "KI-Basierung": "",
    "KI-Komponenten nach Periodensystem der KI": "",
    "KI-Komponenten nach Periodensystem der KI (detailliert)": "",
    "KI-Forschungsfelder nach Russel/Norvig": "",
    "KI-Forschungsfelder nach Russel/Norvig (mit Zuordnung zu Unterkapitel)": "",
    "Quelle KI-Felder": "",
    "Finanzierung": "",
    "Nutzungserfahrungen": "",
    "Leichte-Sprache-Version des Steckbriefes": "",
    "Link(s)": "",
    "Ansprechperson/Abteilung": "",
    "Kommentar/Hinweis für die weitere Analyse": "",
    "Nutzungserfahrungen Kommentar": ""
};

const labelMapping  ={
    "Nr": "Nr (Don't write ID, if this KIAT info is not complete)",  
    "Rechercheupdate Stand": "Rechercheupdate Stand",
    "Name der Technologie": "Name der Technologie *",
    "Kurzbeschreibung": "Kurzbeschreibung",
    "Technologiebeschreibung": "Technologiebeschreibung *",
    "Unterstützungsart": "Unterstützungsart [Wahrnehmen, Kommunizieren, Steuern, Navigieren, Arbeiten, Lernen, Körperlich unterstützen, Psychisch unterstützen]",
    "Unterstützungsart 2": "Unterstützungsart 2 [Wahrnehmen, Kommunizieren, Steuern, Navigieren, Arbeiten, Lernen, Körperlich unterstützen, Psychisch unterstützen]",
    "Unterstützungsart 3": "Unterstützungsart 3 [Wahrnehmen, Kommunizieren, Steuern, Navigieren, Arbeiten, Lernen, Körperlich unterstützen, Psychisch unterstützen]",
    "Berücksichtigte Behinderungsarten (übergeordnet)": "Berücksichtigte Behinderungsarten (übergeordnet) [Psychische Behinderung, Lernbehinderung, Körperliche Behinderung, Hörbehinderung, Sehbehinderung, Chronische Erkrankung, Hoher Unterstützungsbedarf, Keine genannt]",
    "Berücksichtigte Behinderungsarten (übergeordnet) 2": "Berücksichtigte Behinderungsarten (übergeordnet) 2 [Psychische Behinderung, Lernbehinderung, Körperliche Behinderung, Hörbehinderung, Sehbehinderung, Chronische Erkrankung, Hoher Unterstützungsbedarf, Keine genannt]",
    "Berücksichtigte Behinderungsarten (übergeordnet) 3": "Berücksichtigte Behinderungsarten (übergeordnet) 3 [Psychische Behinderung, Lernbehinderung, Körperliche Behinderung, Hörbehinderung, Sehbehinderung, Chronische Erkrankung, Hoher Unterstützungsbedarf, Keine genannt]",
    "Berücksichtigte Behinderungsarten (übergeordnet) 4": "Berücksichtigte Behinderungsarten (übergeordnet) 4 [Psychische Behinderung, Lernbehinderung, Körperliche Behinderung, Hörbehinderung, Sehbehinderung, Chronische Erkrankung, Hoher Unterstützungsbedarf, Keine genannt]",
    "Berücksichtigte Behinderungsarten (übergeordnet) 5": "Berücksichtigte Behinderungsarten (übergeordnet) 5 [Psychische Behinderung, Lernbehinderung, Körperliche Behinderung, Hörbehinderung, Sehbehinderung, Chronische Erkrankung, Hoher Unterstützungsbedarf, Keine genannt]",
    "Berücksichtigte Behinderungsarten (spezifische Behinderung)": "Berücksichtigte Behinderungsarten (spezifische Behinderung)",
    "ICF-Kategorien": "ICF-Kategorien",
    "Schwerpunkt der unterstützten Tätigkeit (Übergreifende Typisierung)": "Schwerpunkt der unterstützten Tätigkeit (Übergreifende Typisierung)",
    "Reifegrad": "Reifegrad [Produkt, Prototyp / Demonstrator, In Entwicklung / Laufendes Projekt]",
    "Laufzeit von Projekten": "Laufzeit von Projekten",
    "Verfügbarkeit von Produkten": "Verfügbarkeit von Produkten [Verfügbar, Nicht mehr verfügbar, Noch nicht verfügbar]",
    "Grundvoraussetzungen zur Anwendung": "Grundvoraussetzungen zur Anwendung",
    "Barrierefreiheit": "Barrierefreiheit [Sprachausgabe als Alternative zum Text, Unterschiedliche Sprachniveaus]",
    "Verfügbare Sprache(n) der Technologie": "Verfügbare Sprache(n) der Technologie",
    "Technische Anforderungen": "Technische Anforderungen",
    "Interoperabilität / Anschlussfähigkeit": "Interoperabilität / Anschlussfähigkeit",
    "KI-Basierung": "KI-Basierung [KI vorhanden, KI in Planung, unklar]",
    "KI-Komponenten nach Periodensystem der KI": "KI-Komponenten nach Periodensystem der KI",
    "KI-Komponenten nach Periodensystem der KI (detailliert)": "KI-Komponenten nach Periodensystem der KI (detailliert)",
    "KI-Forschungsfelder nach Russel/Norvig": "KI-Forschungsfelder nach Russel/Norvig [1) Problemlösen, 2) Wissensrepräsentation und Schlussfolgern, 3) Unsicherheit und Schlussfolgern, 4) Maschinelles Lernen, 5) Kommunizieren, Wahrnehmen und Handeln]",
    "KI-Forschungsfelder nach Russel/Norvig (mit Zuordnung zu Unterkapitel)": "KI-Forschungsfelder nach Russel/Norvig (mit Zuordnung zu Unterkapitel)",
    "Quelle KI-Felder": "Quelle KI-Felder",
    "Finanzierung": "Finanzierung [Keine Kosten lt. Anbieter, Kostenpflichtig (auf Anfrage), Freemium, Keine Angabe / unklar, (noch) keine verfügbare Anwendung]",
    "Nutzungserfahrungen": "Nutzungserfahrungen [Test im Vorgängerprojekt KI.ASSIST, Wissenschaftliche Evaluationsberichte, Publizierte Test-/Evaluationsberichte, Subjektive Erfahrungsberichte, Ausschließlich Anbieterdarstellung, Keine vorliegend]",
    "Leichte-Sprache-Version des Steckbriefes": "Leichte-Sprache-Version des Steckbriefes [umgesetzt, geeignet]",
    "Link(s)": "Link(s)",
    "Ansprechperson/Abteilung": "Ansprechperson/Abteilung",
    "Kommentar/Hinweis für die weitere Analyse": "Kommentar/Hinweis für die weitere Analyse",
    "Nutzungserfahrungen Kommentar": "Nutzungserfahrungen Kommentar",
    "Last Date": "Last Date (Ignore this field)",
}

export default formFields;
export  {labelMapping};

